<template>
  <a
    :href="consoleLink"
    :title="bootData && bootData.user && bootData.user.name ? 'Console' : 'Sign in'"
    :class="bootData && bootData.user && bootData.user.name ? 'user-btn profile-btn': 'sign-in-btn profile-btn'"
    rel="noopener">
    <span class="user-profile" v-if="bootData && bootData.user && bootData.user.user">
      <span class="circle">{{ bootData.user.name.slice(0,1) }}</span>
      <span class="text">Hi, {{bootData.user.name}}</span>
    </span>
    <span v-else>Sign in</span>
  </a>
</template>
<style lang="scss" scoped>
  .profile-btn {
    @media screen and (min-width: 1024px) {
      margin-left: size(20);
    }
    @media screen and (min-width: 768px) {
      margin-left: size(8);
    }
    @media screen and (max-width: 450px) {
      display: block;
    }
  }
  .docs-wrap {
    .overlay-menu, .master-menu-expanded {
      .user-btn {
        transition: all 0.3s ease;
        .user-profile {
          color: var(--rs-white-color);
          @media screen and (min-width: 768px) {
            color: var(--rs-black-color);
          }
          .circle {
            @media screen and (min-width: 768px) {
              background: var(--rs-black-color);
              color: var(--rs-white-color);
            }
          }
        }
      }
    }
  }
  .user-btn {
    padding-left: size(8);
    .user-profile {
      display: flex;
      align-items: center;
      gap: size(8);
      color: var(--rs-black-color);
      font-size: var(--rz-link-reg-font-size);
      line-height: var(--rz-link-reg-line-height);
      font-weight: bold;
      text-transform: capitalize;

      @media screen and (min-width: 768px) {
        color: var(--rs-white-color);
      }

      .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: size(120);
        @media screen and (min-width: 1024px) {
          max-width: 200px;
        }
      }

      .circle {
        display: inline-block;
        padding: size(4);
        font-size: size(12);
        line-height: size(16);
        background: var(--rs-white-color);
        border-radius: 100%;
        width: size(16);
        color: var(--rs-black-color);
        vertical-align: center;
        text-align: center;

        @media screen and (max-width: 450px) {
          border: 1px solid var(--rs-black-color);
        }
      }
    }

    &:hover {
      @include jump-up-animation;
    }
  }
  .sign-in-btn {
    text-transform: initial;
    font-size: var(--rz-link-reg-font-size);
    line-height: var(--rz-link-reg-line-height);
    font-weight: bold;
    color: var(--rs-white-color);
    background: transparent;
    border-radius: size(5);
    padding: size(10) size(20);
    border: size(1) solid var(--rs-white-color);

    @media screen and (max-width: 450px) {
      border: 1px solid var(--rs-black-color);
      color: var(--rs-black-color);
    }

    &:hover {
      @include jump-up-animation;
    }
  }
</style>
<script>
import { mapState } from 'vuex';
import Config from '@/config';

export default {
  name: 'ErrorState',
  computed: {
    ...mapState({
      bootData: (state) => state.boot.instance,
      activeUser: (state) => state.user.activeUser,
    }),
  },
  props: {
    isPaygo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      consoleLink: Config.consoleHost,
    };
  },
};
</script>
