var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"nav-main",class:[!_vm.isRzMenu ? 'menu' : '']},[_c('ul',{class:[_vm.showDownArrow ? 'docs-page': null ]},[_vm._l((_vm.menuItems),function(link,i){return _c('li',{key:i,class:{
        active: _vm.$route.matched.some(function (ref) {
                var name = ref.name;

                return name === link.route;
}),
        'active-parent': _vm.$route.matched.some(function (ref) {
                var meta = ref.meta;

                return meta.baseRouteName === link.route;
}),
        'with-sub-menu': link.haveLinks},attrs:{"id":link.element_id}},[_c('div',{staticClass:"link-item",class:[link.name , link.element_id],on:{"click":function($event){return _vm.onSubMenuClick(link)}}},[(link.routeParams)?_c('router-link',{attrs:{"to":link.routeParams,"exact-active-class":'active'},nativeOn:{"click":function($event){_vm.fireEvent('home-header-' + link.name.toLowerCase())}}},[_c('span',{on:{"click":_vm.closeNavMain}},[_vm._v(" "+_vm._s(link.name)+" ")]),(link.haveLinks)?_c('DropdownIcon',{staticClass:"icon-submenu",attrs:{"rootClass":"regular-inv"}}):_vm._e()],1):(link.url && !link.haveLinks)?_c('a',{attrs:{"href":link.url}},[_vm._v(" "+_vm._s(link.name)+" "),(link.haveLinks)?_c('DropdownIcon',{staticClass:"icon-submenu",attrs:{"rootClass":"regular-inv"}}):_vm._e()],1):_c('span',{staticClass:"link-placeholder"},[_vm._v(" "+_vm._s(link.name)+" "),(link.haveLinks)?_c('DropdownIcon',{staticClass:"icon-submenu",attrs:{"rootClass":"regular-inv"}}):_vm._e()],1),(link.haveLinks)?_c('button',{staticClass:"btn-submenu",attrs:{"aria-label":"Menu Dropdown"}},[(_vm.showDownArrow)?_c('DropdownIcon',{attrs:{"rootClass":"regular-inv","state":link.state.animationState},on:{"updateState":function (state) {_vm.updateLinkAnimationState(link, state)},"iconClick":function (state) {_vm.onSubMenuIconClick(link, state)}}}):_vm._e()],1):_vm._e(),(link.element_id == 'paygo_link' && link.desc)?_c('span',{staticClass:"highlight-text"},[_vm._v(" "+_vm._s(link.desc)+" ")]):_vm._e()],1),(link.haveLinks &&
        (link.element_id === 'productApi' || link.element_id === 'graphqlEndpoints'))?_c('MasterMenuApiSubMenu',{attrs:{"endPoints":link.linkslist,"linkID":link.element_id}}):_vm._e(),((link.element_id !== 'productApi' && link.element_id !== 'graphqlEndpoints'))?_c('div',{staticClass:"sub-menu-items",class:[{expanded: link.state.expanded, 'begin-collapse': link.state.beginCollapse,
        }, link.name]},[(link.haveLinks)?_c('MasterMenuSubMenu',{attrs:{"isRzMenu":_vm.isRzMenu,"linkslist":link.linkslist,"animState":_vm.closeNavMain},on:{"update:animState":function($event){_vm.closeNavMain=$event},"update:anim-state":function($event){_vm.closeNavMain=$event}}}):_vm._e()],1):_vm._e()],1)}),(_vm.isRzMenu)?[_c('li',{staticClass:"support-no show-on-desktop"},[_c('div',{staticClass:"link-item text-button"},[_c('a',{staticClass:"link-plain",attrs:{"href":_vm.supportNumbers.indianNumberSales.link,"target":"_self","title":"Indian Support Number"}},[_vm._v(_vm._s(_vm.supportNumbers.indianNumberSales.label))])])]),_c('li',{staticClass:"show-on-mobile support-no-mobile"},[_c('div',{staticClass:"link-item"},[_vm._m(0),_c('a',{staticClass:"link-plain",attrs:{"href":_vm.supportNumbers.usTollfree.link,"target":"_self","title":"US Tollfree Number"}},[_vm._v(_vm._s(_vm.supportNumbers.usTollfree.label))])]),_vm._m(1)]),_c('li',{staticClass:"show-on-mobile support-no-mobile"},[_c('div',{staticClass:"link-item"},[_vm._m(2),_c('a',{staticClass:"link-plain",attrs:{"href":_vm.supportNumbers.indianNumber.link,"target":"_self","title":"Indian Support Number"}},[_vm._v(_vm._s(_vm.supportNumbers.indianNumber.label))])]),_vm._m(3)]),_vm._m(4)]:_vm._e()],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"support-icon hide-on-mobile"},[_c('img',{attrs:{"src":require("@/assets/phone-icon-us.svg"),"alt":"Phone-Call"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"desc-area"},[_c('span',{staticClass:"markdown"},[_c('span',{staticClass:"show-on-mobile"},[_c('img',{attrs:{"src":require("@/assets/phone-icon-us.svg"),"alt":"Phone-Call"}})]),_c('span',{staticClass:"left-space"},[_vm._v("US Tollfree Number")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"support-icon hide-on-mobile"},[_c('img',{attrs:{"src":require("@/assets/phone-icon-us.svg"),"alt":"Phone-Call"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"desc-area"},[_c('span',{staticClass:"markdown"},[_c('span',{staticClass:"show-on-mobile"},[_c('img',{attrs:{"src":require("@/assets/phone-icon-us.svg"),"alt":"Phone-Call"}})]),_c('span',{staticClass:"left-space"},[_vm._v("Indian Support Number")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"show-on-mobile support-mail"},[_c('div',{staticClass:"link-item"},[_c('span',{staticClass:"support-icon hide-on-mobile"},[_c('img',{attrs:{"src":require("@/assets/message-icon-colored.svg"),"alt":"message-icon"}})]),_c('a',{staticClass:"link-plain",attrs:{"href":"mailto:support@cricketapi.com","target":"_self","title":"Support Mail"}},[_vm._v(" support@cricketapi.com ")])]),_c('div',{staticClass:"desc-area"},[_c('span',{staticClass:"markdown"},[_c('span',{staticClass:"show-on-mobile"},[_c('img',{attrs:{"src":require("@/assets/message-icon-colored.svg"),"alt":"message-icon"}})]),_c('span',{staticClass:"left-space"},[_vm._v("Send us message")])])])])}]

export { render, staticRenderFns }