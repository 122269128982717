<template>
  <nav class="nav-main" :class="[!isRzMenu ? 'menu' : '']">
    <ul :class="[showDownArrow ? 'docs-page': null ]">
      <li
        v-for="(link, i) in menuItems"
        :id="link.element_id"
        :key="i"
        :class="{
          active: $route.matched.some(({ name }) => name === link.route),
          'active-parent': $route.matched.some(({ meta }) => meta.baseRouteName === link.route),
          'with-sub-menu': link.haveLinks}">
        <div class="link-item" :class="[link.name , link.element_id]"
          @click="onSubMenuClick(link)">
          <router-link
            v-if="link.routeParams"
            :to="link.routeParams"
            @click.native="fireEvent('home-header-' + link.name.toLowerCase())"
            :exact-active-class="'active'">
            <span @click="closeNavMain"> {{link.name}} </span>
            <DropdownIcon class="icon-submenu" v-if="link.haveLinks" rootClass="regular-inv"/>
          </router-link>
          <a :href="link.url" v-else-if="link.url && !link.haveLinks">
            {{link.name}}
            <DropdownIcon class="icon-submenu" v-if="link.haveLinks" rootClass="regular-inv"/>
          </a>
          <span class="link-placeholder" v-else>
            {{link.name}}
            <DropdownIcon  class="icon-submenu" v-if="link.haveLinks" rootClass="regular-inv"/>
          </span>
          <button class="btn-submenu" v-if="link.haveLinks" aria-label="Menu Dropdown">
            <DropdownIcon v-if="showDownArrow"
            rootClass="regular-inv"
            :state="link.state.animationState"
            @updateState="(state) => {updateLinkAnimationState(link, state)}"
            @iconClick="(state) => {onSubMenuIconClick(link, state)}"
            />
          </button>
          <span v-if="link.element_id == 'paygo_link' && link.desc" class="highlight-text">
            {{link.desc}}
          </span>
        </div>
        <MasterMenuApiSubMenu :endPoints="link.linkslist"  :linkID="link.element_id"
          v-if="link.haveLinks &&
          (link.element_id === 'productApi' || link.element_id === 'graphqlEndpoints')"/>
        <div
          class="sub-menu-items"
          :class="[{expanded: link.state.expanded, 'begin-collapse': link.state.beginCollapse,
          }, link.name]"
          v-if="(link.element_id !== 'productApi' && link.element_id !== 'graphqlEndpoints')"
          >
          <MasterMenuSubMenu :isRzMenu="isRzMenu" :linkslist="link.linkslist" v-if="link.haveLinks"
          :animState.sync="closeNavMain"/>
        </div>
      </li>
      <template v-if="isRzMenu">
        <li class="support-no show-on-desktop">
          <div class="link-item text-button">
            <!-- <a :href="supportNumbers.usTollfree.link" target="_self" class="link-plain"
              title="US Tollfree Number">{{supportNumbers.usTollfree.label}}</a> -->
            <a :href="supportNumbers.indianNumberSales.link" target="_self" class="link-plain"
              title="Indian Support Number">{{supportNumbers.indianNumberSales.label}}</a>
          </div>
        </li>
        <li class="show-on-mobile support-no-mobile">
          <div class="link-item">
            <span class="support-icon hide-on-mobile">
              <img src="@/assets/phone-icon-us.svg" alt="Phone-Call">
            </span>
            <a :href="supportNumbers.usTollfree.link" target="_self" class="link-plain"
              title="US Tollfree Number">{{supportNumbers.usTollfree.label}}</a>
          </div>
          <div class="desc-area">
            <span class="markdown">
              <span class="show-on-mobile">
                <img src="@/assets/phone-icon-us.svg" alt="Phone-Call"></span>
              <span class="left-space">US Tollfree Number</span>
            </span>
          </div>
        </li>
        <li class="show-on-mobile support-no-mobile">
          <div class="link-item">
            <span class="support-icon hide-on-mobile">
              <img src="@/assets/phone-icon-us.svg" alt="Phone-Call">
            </span>
            <a :href="supportNumbers.indianNumber.link" target="_self" class="link-plain"
              title="Indian Support Number">{{supportNumbers.indianNumber.label}}</a>
          </div>
          <div class="desc-area">
            <span class="markdown">
              <span class="show-on-mobile">
                <img src="@/assets/phone-icon-us.svg" alt="Phone-Call"></span>
              <span class="left-space">Indian Support Number</span>
            </span>
          </div>
        </li>
        <li class="show-on-mobile support-mail">
          <div class="link-item">
            <span class="support-icon hide-on-mobile">
              <img src="@/assets/message-icon-colored.svg" alt="message-icon">
            </span>
            <a href="mailto:support@cricketapi.com" target="_self" class="link-plain"
              title="Support Mail">
              support@cricketapi.com
            </a>
          </div>
          <div class="desc-area">
            <span class="markdown">
              <span class="show-on-mobile">
                <img src="@/assets/message-icon-colored.svg" alt="message-icon"></span>
              <span class="left-space">Send us message</span>
            </span>
          </div>
        </li>
      </template>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
  .nav-main {
    --dropdown-icon-width: #{size(16)};

    @media screen and (min-width: $breakpoint-md) {
      --dropdown-icon-width: #{size(16)};
    }

    @media screen and (min-width: $breakpoint-lg) {
      --dropdown-icon-width: #{size(9)};
    }

    overflow: auto;
    > ul.docs-page {
      margin: size(10) size(16) size(0) size(16);
      font-size: size(16);

      li {
        border-bottom: 1px solid var(--divider);

        &:last-child {
          border-bottom: 0;
        }

        &.active, &.active-parent {
          font-weight: bold;
        }

        .link-item {
          display: flex;
          justify-content: space-between;
        }

        .btn-submenu, .icon-submenu {
          width: var(--dropdown-icon-width);
          height: var(--dropdown-icon-width);
          margin: auto 0;
        }

        .btn-submenu {
          display: none;
        }
        .icon-submenu {
          display: none;
          padding-left: size(5);
        }

        a, .link-placeholder {
          padding: size(13) size(13) size(13) 0;
          flex: 1;
          color: var(--regular-text-inv);
          cursor: pointer;

          &:hover {
            @include jump-up-animation;
            // font-weight: bold;
          }
        }
      }
    }
    .sub-menu-items {
      max-height: 0;
      overflow: hidden;
      transition: max-height 550ms ease-in;
      &.expanded {
        animation: grow 450ms forwards ease-out;
        max-height: #{size(500)};
      }

      &.begin-collapse {
        animation: grow-rev 450ms forwards ease-out;
      }
    }

    .highlight-text {
      font-size: size(13);
      line-height: size(24);
      color: var(--onboard-link-color);
      vertical-align: super;
      font-weight: bold;
      cursor: pointer;
    }

    @media screen and (min-width: $breakpoint-md) {
      > ul.docs-page {
        margin: size(20) size(27) size(40) size(63);
        font-size: size(18);
      }
    }

    @media screen and (min-width: $breakpoint-lg) {
      > ul.docs-page {
        margin: size(1) 0;

        li {
          display: inline-block;
          border-bottom: none;
          $_border-height: 5px;

          .link-item {
            border-bottom: $_border-height solid transparent;
            transition: 400ms ease-in;
            display: inline-block;
            &.paygo_link {
              display: none;
            }
            &:hover {
              border-color: var(--master-highlight);
            }
          }

          &.active, &.active-parent {
            .link-item {
              border-color: var(--master-highlight);
            }
          }

          .btn-submenu {
            display: none;
          }
          .icon-submenu {
            transition: 400ms ease-in;
            // display: inline-block;
            display: none;
            opacity: 1;
          }

          a, .link-placeholder{
            line-height: calc(var(--master-menu-height) - #{size(2)} - #{$_border-height * 2});
            display: inline-block;
            padding: 0 size(20);
            padding-top: $_border-height;
            &.active, &:hover {
              background: inherit;
              animation: none;
            }

            &.active {
              font-weight: bold;
            }
          }

          &.with-sub-menu {
            &:hover {
              .icon-submenu {
                opacity: 0;
              }
              .link-item {
                border-color: transparent;
              }

              .sub-menu-items {
                display: block;
                animation: drop-menu 500ms ease-in;

                ul {
                  animation: opacity-fill 500ms ease-in 350ms forwards;
                }
              }
            }
          }
        }
      }

      .sub-menu-items {
        min-width: size(116);
        position: fixed;
        background: var(--master-bg);
        display: none;
        height: auto;

        &.expanded {
          animation: none;
        }

        ul {
          opacity: 0;
        }
        animation: drop-menu 500ms ease-in reverse;
      }
    }
  }
  @media screen and (min-width: $breakpoint-lg) {
    .master-menu {
      &.overlay-menu {
        .nav-main {
          >ul {
            li {
              .link-placeholder, a {
                color: var(--secondary-text);
              }
            }
          }
        }
      }
    }
  }
  .link-item {
    &.text-button {
      a {
        font-size: var(--rz-link-reg-font-size);
        line-height: var(--rz-link-reg-line-height);
        font-weight: bold;
        color: var(--rz-link-color);
        @media screen and (min-width: $breakpoint-lg) {
          padding-left: size(160);
        }
      }
    }
  }
</style>

<style lang="scss">
.nav-main {
  .with-sub-menu {
    .endpoint-submenu-container {
      @media screen and (min-width: $breakpoint-md) {
        display: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      @media screen and (min-width: $breakpoint-lg) {
        transform: translateX(-50%);
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      &:hover .endpoint-submenu-container {
        display: block;
      }
    }
  }
}
</style>

<script>
import { supportNumbers } from '@/store/modules/page';
import { SmartMorphAnimationState } from '@/core/view/smartMorph';
import DropdownIcon from '@/components/InteractiveIcons/DropdownIcon.vue';
import MasterMenuSubMenu from './MasterMenuSubMenu.vue';
import MasterMenuApiSubMenu from './MasterMenuApiSubMenu.vue';

export default {
  components: { DropdownIcon, MasterMenuSubMenu, MasterMenuApiSubMenu },
  props: {
    menuItems: {
      type: Array,
    },
    activeLink: String,
    showDownArrow: {
      type: Boolean,
      default: true,
    },
    isRzMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabBreakpoint: 1194,
      supportNumbers,
    };
  },
  mounted() {
    // Docs page sub-menu for Mobile view is togglable while Cricket landing page sub-menu is not.
    if (!this.showDownArrow) {
      this.openAllSubMenu();
    }
  },
  methods: {

    onSubMenuIconClick(link, state) {
      if (!state.isEnd) {
        this.closeSubMenu(link);
      } else {
        this.openSubMenu(link);
      }
    },

    onSubMenuClick(link) {
      if (!this.showDownArrow) {
        return;
      }
      if ((window.innerWidth < this.tabBreakpoint) && (link.linkslist && link.linkslist.links.length > 0)) {
        if (link.state.expanded) {
          this.updateLink(link, { animationState: SmartMorphAnimationState.Init });
          this.closeSubMenu(link);
        } else {
          this.updateLink(link, { animationState: SmartMorphAnimationState.End });
          this.openSubMenu(link);
        }
      }
    },

    openSubMenu(link) {
      this.updateLink(link, { beginCollapse: false, expanded: true });
    },

    closeSubMenu(link) {
      this.updateLink(link, { beginCollapse: true });

      // Wait for animation to finish
      setTimeout(() => {
        this.updateLink(link, { beginCollapse: false, expanded: false });
      }, 455);
    },

    updateLinkAnimationState(link, animationState) {
      this.updateLink(link, { animationState });
    },

    updateLink(link, data) {
      this.$store.commit('link/updateMasterLinkState', { index: link.index, ...data });
    },

    closeNavMain() {
      if (this.showDownArrow && (window.innerWidth < this.tabBreakpoint)) {
        this.$emit('update:animState', SmartMorphAnimationState.Init);
        // Wait for animation to finish
        setTimeout(() => {
          this.closeAllSubMenu();
        }, 455);
      }
    },

    openAllSubMenu() {
      this.menuItems.forEach((link) => {
        if (link.linkslist) {
          this.openSubMenu(link);
          this.updateLink(link, { animationState: SmartMorphAnimationState.End });
        }
      });
    },

    fireEvent(event) {
      window.dataLayer.push({ event });
    },
  },
};
</script>
